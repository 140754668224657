* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: #131921;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 60px;
}

.header__logo {
  height: 55px;
  width: 100px;
  object-fit: contain;
  margin-left: 20px;
  margin-right: 20px;
}

.select-before {
  min-width: 90px;
  background-color: #f3f3f3;
}

.header__search {
  width: 100%;
  padding: 0;
}

.header__searchIcon {
  background-color: #cd9042;
  padding: 4px;
  height: 21px !important;
}

.header__nav{
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  min-width: 350px;
}

.header__option{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.header__link{
  color: white;
  text-decoration: none;
}

.header__optionLineOne{
  font-size: 12px;
}

.header__optionLineTwo{
  font-size: 14px;
  font-weight: 700;
}

.header__optionLineThree{
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: 12px;
  right: -11px;
}

.header__optionBasket{
  height: 39px;
  width: 48px;
  background-image: url(./images/cart.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.header__basketCount{
  margin-left: 21px;
  font-size: 18px;
  color: #f08804;
  font-weight: 700;
  position: relative;
  top: -5px;
}

.home {
  max-width: 1500px;
  margin: auto;
  background-color: rgb(234,237,237);
}

.home__img {
  height: 600px;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: -1;
  margin-bottom: -315px;
}


.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: 400px;
  min-width: 100px;
  margin: 10px;
  padding: 20px;
  width: 320px;
  background-color: white;
  z-index: 1;
}

.product__info {
  height: 100px;
  margin-bottom: 15px;
}

.product img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product button {
  background-color: #f0c14b;
  border: 1px solid #a88734;
  max-width: 200px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
  background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
  font-size: 13px;
  height: 29px;
}

.product__price {
  margin-top: 5px;
  font-size: 16px;
}

.home__row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.checkoutProduct{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 60px;
  border-top:  1px solid #DDD;
}

.checkoutProduct__image{
object-fit: contain;
width:180px;
height: 180px;
margin-top: 10px;
}

.checkoutProduct__info{
padding-left: 20px;
width: 100%;
display: flex;
flex-direction: column;
margin-top: 10px;
}

.checkoutProduct__price {
  font-size: 18px;
  font-weight: 500;
  float: right; 
  width: 200px; 
  text-align: right;
  margin-top: 10px;
}

.checkoutProduct_title{
font-size: 18px;
font-weight: 500;
color: #007185;
float: left;
}

.checkoutProduct button{
  background-color: #f0c14b;
  border: 1px solid #a88734;
  margin-top: 10px;
  color: #111;
}

.checkout {
padding: 20px;
background-color: white;
height: max-content;
}

.checkout_left {
  width: 100%;
  margin-top: 40px;
 
}

.checkout_left_left {
display: flex; 
}

.checkout_left h2 {
  font-weight: 700;
  font-size: 28px;
}

.font_12 {
  font-size: 12px;
}

.green {
  color: #007600;
}

.checkout__ad {
  width: 100%;
  margin-bottom: 10px;
  height: 200px;
  border: 1px solid #c9e1f4;
}

.checkout__empty {
  height: 250px;
  width: 500px;
}

.checkout_right {
  max-width: 400px;
  height: 150px;
  border-radius: 0 0 8px 8px;
  background-color: #F0F2F2;
  border: 1px #D5D9D9 solid;
  padding: 15px;
}

.checkout_right p{
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.checkout_left button {
  background-color: #f0c14b;
  border: 1px solid #a88734;
  max-width: 100px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
  background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
  font-size: 13px;
  height: 29px;
}

.yellow_button {
  background-color: #f0c14b;
  border: 1px solid #a88734;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
  background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
  font-size: 13px;
  height: 29px;
  margin-top: 15px;
}

@media only screen and (max-width: 600px) {
  .home__img {
    display: none;
  }

  .header__logo{
    margin: 0;
    height: 25px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 8px;
  }

  .header {
    height: 90px;
    position: relative;
  }

  .header__search {
    position: absolute;
    bottom: 0;
    width: 80%;
    left: 10%;
    margin: 5px;
  }

  .header__option {
    margin: 0 10px;
    padding: 0;
  }
  
  .header__nav {
    position: absolute;
    top: 3px;
    right: 15%;
    max-width: 300px;
    min-width: 200px;
    justify-content: flex-start;
  }

  .checkout__ad {
    display: none;
  }

  .checkout_left_left {
    flex-direction: column-reverse;
    }

    .checkoutProduct__image{
      max-width: 100px;
    }

    .checkoutProduct__price{
      display: none;
    }

    .checkoutProduct {
      margin: 5px 0;

    }

    .checkout_left {
      margin-top: 5px;
    }
}
